import posthog, { Properties } from 'posthog-js';
import { posthog as config, app } from '../infra/config';

let initialized = false;

const lazyInit = () => {
    if (!initialized) {
        // can't disable posthog in dev because feature flags depend on it
        posthog.init(config.POSTHOG_KEY!, {
            autocapture: (app.ENVIRONEMNT !== 'Development'),
            capture_pageview: (app.ENVIRONEMNT !== 'Development'),
            debug: app.ENVIRONEMNT === 'Development',
            person_profiles: 'identified_only',
            api_host: config.POSTHOG_HOST,

            session_recording: {
                maskAllInputs: true,
                maskInputFn: (text: any, element: any) => {
                    if (element?.dataset.phMask === 'true') {
                        return '*'.repeat(text.length); // Mask text if data-ph-mask is true
                    }
                    return text; // Don't mask other inputs
                },
            },
        });
        initialized = true;
    }
};

export const PostHog = {
    initialize: () => {
        if (document.readyState === 'complete') {
            lazyInit();
        } else {
            window.addEventListener('load', lazyInit);
        }
    },

    setIdentity: (userId: any, properties = {}) => {
        if (!initialized) {
            lazyInit();
        }
        posthog.identify(userId, properties);
    },

    track: (eventName: string, properties: Properties) => {
        if (!initialized) {
            lazyInit();
        }
        posthog.capture(eventName, properties);
    },

    setUserProperties: (properties: any) => {
        if (!initialized) {
            lazyInit();
        }
        posthog.people.set(properties);
    },
};
